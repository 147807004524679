<template>
  <div>
    <v-card
      elevation='3'
      outlined
      width='450'
      :height='stepSendCodeEmail ? "400" : "430"'
    >
      <v-container fluid justify='center' align='center' class='h-100'>
        <v-row no-gutters>
          <v-card-text class='pt-0'>
            <div v-if='stepSendCodeEmail'>
              <v-card-title class='pl-0'>
                {{$t('RECOVER_PASSWORD')}}
              </v-card-title>
              <v-card-subtitle class='pl-0'>
                {{$t('ENTER_YOUR_EMAIL_TO_RECEIVE_THE_CONFIRMATION_CODE')}}
              </v-card-subtitle>

              <v-form ref='formEmailIsValid' v-model='formEmailIsValid' lazy-validation>
                <v-text-field
                  v-model='user.email'
                  :rules='[rules.required]'
                  :label='$t("EMAIL")'
                />
              </v-form>

              <v-btn block color='primary' class='mt-5' @click='sendRecoverCode'>
                {{$t('GET_RECOVER_CODE')}}
              </v-btn>
              <v-btn block text color='primary' class='mt-5' @click='stepSendCodeEmail = false'>
                {{$t('ALREADY_HAVE_A_CONFIRMATION_CODE')}}
              </v-btn>
            </div>

            <div v-else>
              <v-card-title class='pl-0'>
                {{$t('RECOVER_PASSWORD')}}
              </v-card-title>

              <v-form ref='formIsValid' v-model='formIsValid' lazy-validation>
                <v-text-field
                  v-model='user.email'
                  :rules='[rules.required]'
                  :label='$t("EMAIL")'
                />
                <v-text-field
                  v-model='user.code'
                  :rules='[rules.required]'
                  :label='$t("CONFIRMATION_CODE")'
                />
                <v-text-field
                  v-model='user.password'
                  :rules='[rules.required]'
                  :label='$t("PASSWORD")'
                  :type='showPassword ? "text" : "password"'
                  :append-icon='showPassword ? "mdi-eye" : "mdi-eye-off"'
                  @click:append='togglePasswordVisibility'
                />
                <v-text-field
                  v-model='user.confirmPassword'
                  :rules='[rules.required, rules.passwordMatch]'
                  :label='$t("CONFIRM_PASSWORD")'
                  :type='showPassword ? "text" : "password"'
                  :append-icon='showPassword ? "mdi-eye" : "mdi-eye-off"'
                  @click:append='togglePasswordVisibility'
                />
              </v-form>

              <v-btn block color='primary' class='mt-2' @click='recoverPassword'>
                {{$t('CHANGE_PASSWORD')}}
              </v-btn>
            </div>
          </v-card-text>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      flat
      dark
      width='450'
      max-height='450'
      style='margin-top: 15px; background: none;'
    >
      <v-row no-gutters class='h-100' align='center' justify='center'>
        <span>
          {{$t('ALREADY_HAVE_AN_ACCOUNT')}}
          <v-btn text @click='toggleRecoverPassword'>
            {{$t('SIGNIN')}}
          </v-btn>
        </span>
      </v-row>
    </v-card>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'RecoverPassword',
    data: function () {
      return {
        stepSendCodeEmail: true,
        formEmailIsValid: true,
        formIsValid: true,
        showPassword: false,
        rules: {
          required: (value) => !!value || this.$t('REQUIRED'),
          passwordMatch: (value) => (value === this.user.password) || this.$t('PASSWORDS_ENTERED_DO_NOT_MATCH'),
        },
        user: {
          email: undefined,
          code: undefined,
          password: undefined,
          confirmPassword: undefined,
        },
      };
    },
    methods: {
      toggleRecoverPassword: function () {
        this.$emit('toggle-recover-password');
      },
      togglePasswordVisibility: function () {
        this.showPassword = !this.showPassword;
      },
      sendRecoverCode: async function () {
        this.formEmailIsValid = this.$refs.formEmailIsValid.validate();
        if (!this.formEmailIsValid) {
          return;
        }

        try {
          this.$store.dispatch('loading/toggleLoading');
          await axios({
            url: '/account-request-recover',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            data: {
              email: this.user.email,
            },
          });

          this.stepSendCodeEmail = false;
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          this.$store.dispatch('loading/toggleLoading');
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      recoverPassword: async function () {
        this.formIsValid = this.$refs.formIsValid.validate();
        if (!this.formIsValid) {
          return;
        }

        try {
          this.$store.dispatch('loading/toggleLoading');
          await axios({
            url: '/account-recover-password',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            data: {
              email: this.user.email,
              password: this.user.password,
              code: this.user.code,
            },
          });

          this.toggleRecoverPassword();
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          this.$store.dispatch('loading/toggleLoading');
          console.log(e); /* eslint-disable-line no-console */
        }
      },
    },
  };
</script>
